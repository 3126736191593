var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "replay-tree" },
    _vm._l(_vm.commentList, function (item) {
      return _c("div", { key: item.id, staticClass: "replay-list" }, [
        _c("div", { staticClass: "reference-list" }, [
          _c(
            "div",
            { staticClass: "header-replay" },
            [
              _c("el-avatar", {
                attrs: {
                  size: 25,
                  src: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                },
              }),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(item.createUserName)),
              ]),
              _c("div", { staticClass: "infor-text" }, [
                _vm._v(_vm._s(item.content)),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "replay-infor" }, [
            _c("div", { staticClass: "infor-time" }, [
              _c("div", { staticClass: "time" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm")
                  )
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "time",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.commentUp(item)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-dianzan1" }),
                  _c("span", { staticClass: "num" }, [_vm._v(_vm._s(item.up))]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "time",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.commentDown(item)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-cai1" }),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(item.down)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "replay-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showReplay(item)
                    },
                  },
                },
                [_vm._v("回复")]
              ),
            ]),
          ]),
          item.showReplay
            ? _c(
                "div",
                [
                  _c("CommentOn", {
                    ref: "contentReplay",
                    refInFor: true,
                    attrs: {
                      "show-header": false,
                      "placeholder-tips": _vm.placeholderTips,
                      "is-replay-sub": _vm.isReplaySub,
                      "current-item-data": _vm.currentItemData,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        item.reCommentList && item.reCommentList.length > 0
          ? _c(
              "div",
              [_c("Tree", { attrs: { "comment-list": item.reCommentList } })],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }