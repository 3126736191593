var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-on" }, [
    _vm.showHeader
      ? _c("h1", { staticClass: "title" }, [_vm._v("评论")])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "replay-container" },
      [
        _c("el-avatar", {
          attrs: {
            size: 40,
            src: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          },
        }),
        _c("el-input", {
          staticClass: "replay-input",
          attrs: {
            placeholder: _vm.placeholderTips
              ? _vm.placeholderTips
              : "请输入内容",
          },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        }),
        _c(
          "el-button",
          {
            staticClass: "btn",
            attrs: {
              type: "primary",
              size: "mini",
              disabled: _vm.content.length <= 0,
            },
            on: { click: _vm.commentAdd },
          },
          [_vm._v("发 布")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }