var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "main-wrap" }, [
        _c("div", { staticClass: "nav-breadcrumb" }, [_c("Breadcrumb")], 1),
        _c("div", { staticClass: "right-aside-container" }, [
          _c(
            "div",
            { staticClass: "right-aside-wrap" },
            [
              _c("RecommendListComponent", {
                attrs: { "recommend-list": _vm.recommendList },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "left-aside-container" }, [
          _c(
            "div",
            { staticClass: "left-aside-wrap" },
            [
              _c("div", { staticClass: "article-details" }, [
                _c("h1", {
                  staticClass: "title",
                  domProps: { textContent: _vm._s(_vm.articleDetails.title) },
                }),
                _c("div", { staticClass: "publish-info" }, [
                  _c("span", {
                    staticClass: "author",
                    domProps: {
                      textContent: _vm._s(_vm.articleDetails.author),
                    },
                  }),
                  _c("span", [_vm._v("发布时间：")]),
                  _c("span", { staticClass: "publish-time" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatDate(
                          new Date(_vm.articleDetails.gmtCreate),
                          "yyyy-MM-dd hh:mm"
                        )
                      )
                    ),
                  ]),
                ]),
                _vm.articleDetails.brief
                  ? _c("div", { staticClass: "brief" }, [
                      _vm._v(_vm._s(_vm.articleDetails.brief)),
                    ])
                  : _vm._e(),
                _c("div", {
                  staticClass: "content",
                  domProps: { innerHTML: _vm._s(_vm.articleDetails.detail) },
                }),
                _c(
                  "div",
                  { staticClass: "crown-or-step" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: { value: _vm.articleDetails.up },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-dianzan1",
                          on: { click: _vm.contentUp },
                        }),
                      ]
                    ),
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: { value: _vm.articleDetails.down },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-cai1",
                          on: { click: _vm.contentDown },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("Replay", {
                ref: "replayMore",
                attrs: { "comment-list": _vm.commentList },
                on: { loadDetail: _vm.loadDetail },
              }),
              _c("FriendLinks"),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }